import React, { useState, useEffect } from "react";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import "./Css/BlogDetail.css";
import { useLocation } from "react-router-dom";
import { formatDateString, handleError, handleLinkClick } from "../CommonJquery/CommonJquery";
import {server_post_data, blog_detail } from '../ServiceConnection/serviceconnection'
import DOMPurify from "dompurify";
import Loader from "./Reapeting Componets/Loader";
export default function BlogDetail() {
  const [overlayWidth, setOverlayWidth] = useState("100%");
  const [textWidth, setTextwidth] = useState("0");
  const [textWidth2, setTextwidth2] = useState("100%");

  const [blogPosts, setcancelledData] = useState([]);
  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  useEffect(() => {
    master_data_get_details();
  }, []);

  const master_data_get_details = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("current_url", "/" + currentUrl);
    await server_post_data(blog_detail, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          if (final_data.blog.length >0) {
            setcancelledData(final_data.blog[0]);
          }
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error)
        setShowLoaderAdmin(false);
      });
  };
 

  useEffect(() => {
    const timer = setTimeout(() => {
      setOverlayWidth("0");
      setTextwidth2("100%");
      setTextwidth("90%");
      setTextwidth2("0");
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="blogDetaildiv">
       {showLoaderAdmin && <Loader />}
      <Header />
      <div className="searvicDEtailDiv" style={{ overflow: "hidden" }}>
        <div className="heroServive">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="leftService">
                <div
                  className="overlay"
                  style={{
                    width: overlayWidth,
                    transition: "width 1.3s ease-in-out",
                  }}
                ></div>
                <div className="imgOverlAy"></div>
                <img
                  src={blogPosts.image_name}
                  alt="The Fox Advertisement Service Hero Image"
                  title="The Fox Advertisement Service Hero Image"
                  loading="lazy"
                  className="responsive-img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="rightService">
                <div className="detailServHeroSubBlogg">
                  <div
                    className="overlaytEXT"
                    style={{
                      width: textWidth2,
                      transition: "width 1s ease-in-out",
                    }}
                  ></div>
                  <h2>{blogPosts.title_name}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <div className="avhivedDiv">
          {/* {blogPosts.map((section, index) => ( */}
            <div  className="blogDetaiuldata">
              <h1>{blogPosts.title_name}</h1>
              <p dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(blogPosts.description),
            }}></p>
            </div>
          {/* ))} */}

          <div className="theFoxTxtbttm">
            <label>{blogPosts.author}</label>
            <label>{formatDateString(blogPosts.entry_date)}</label>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
