import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet
import "./Css/BrandTrusted.css";
import clg1 from "../Assets/lnctImg.png";
import urSpace from "../Assets/urSpace.png";
import ssgu from "../Assets/ssgu.png";
import jkMedical from "../Assets/jkMedical.png";
import aiset from "../Assets/aiset.png";
import goyal from "../Assets/goyal.png";
import ezeseed from "../Assets/ezeseed.png";
import cosmeka from "../Assets/cosmeka.png";
import bksolar from "../Assets/bksolar.png";
import jainTubes from "../Assets/jainTubes.png";
import ims from "../Assets/ims.png";

export default function BrandTrusted() {
  const brands = [
    {
      src: clg1,
      alt: "LNCT University - Trusted Partner",
      title: "LNCT University - Trusted Partner",
    },
    {
      src: urSpace,
      alt: "UR Space - Leading Space Provider",
      title: "UR Space - Leading Space Provider",
    },
    {
      src: ssgu,
      alt: "SSGU University - Trusted Education Partner",
      title: "SSGU University - Trusted Education Partner",
    },
    {
      src: jkMedical,
      alt: "JK Medical - Trusted Healthcare Provider",
      title: "JK Medical - Trusted Healthcare Provider",
    },
    {
      src: aiset,
      alt: "AISET - Advanced Technology Solutions",
      title: "AISET - Advanced Technology Solutions",
    },
    {
      src: goyal,
      alt: "Goyal Industries - Reliable Manufacturing",
      title: "Goyal Industries - Reliable Manufacturing",
    },
    {
      src: ezeseed,
      alt: "Ezeseed - Innovative Seed Solutions",
      title: "Ezeseed - Innovative Seed Solutions",
    },
    {
      src: cosmeka,
      alt: "Cosmeka - Professional Beauty Products",
      title: "Cosmeka - Professional Beauty Products",
    },
    {
      src: bksolar,
      alt: "BK Solar - Renewable Energy Solutions",
      title: "BK Solar - Renewable Energy Solutions",
    },
    {
      src: jainTubes,
      alt: "Jain Tubes - Reliable Pipe Supplier",
      title: "Jain Tubes - Reliable Pipe Supplier",
    },
    {
      src: ims,
      alt: "IMS - Leading Management Solutions",
      title: "IMS - Leading Management Solutions",
    },
  ];

  return (
    <section className="work py-5 bg-black">
      {/* Setting the SEO Title using Helmet */}
      <div className="container">
        <div className="brandTrustdHead">
          <h1>BRANDS TRUSTED US</h1>
        </div>
        <div className="row mt-3 contrImng">
          {brands.map((brand, index) => (
            <div
              key={index}
              className={`col-lg-3 col-md-4 col-sm-6 col-6 borderbottm ${
                index % 3 === 0 ? "borderLeft" : ""
              }`}
            >
              <div className="divone">
                <img src={brand.src} alt={brand.alt} title={brand.title} />
              </div>
            </div>
          ))}
          <div className="col-lg-3 col-md-4 col-6 sportBttn borderbottm">
            <button className="btn-4">
              <span>
                THIS SPOT <br /> CAN BE <br /> YOURS
              </span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
