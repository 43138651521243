import { useState, useEffect } from "react";
import Header from "./Reapeting Componets/Header";
import Hero from "./Hero";
import RecentWork from "./RecentWork";
import Testimonial from "./Testimonial";
import BrandTrusted from "./BrandTrusted";
import Footer from "./Reapeting Componets/Footer";
import "./Css/Home.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import NavigationWithAnimation from "../CommonJquery/NavigationWithAnimation";
import Loader from "./Reapeting Componets/Loader";
import { server_post_data, blog } from "../ServiceConnection/serviceconnection";
import { formatDateString, handleError } from "../CommonJquery/CommonJquery.js";
export default function Home() {
  const { handleNavigationRoute } = NavigationWithAnimation();
  const [h1Width, setH1Width] = useState("0%");
  const [SEOloop, setSEOloop] = useState([]);
  useEffect(() => {
    const handleScroll = () => {
      // Get the h1 element by its ID
      const h1Element = document.getElementById("blogHome");

      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top >= 0 && h1Top <= windowHeight * 0.8) {
          setH1Width("100%");
        } else {
          setH1Width("0%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  ///blog data

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "blog/blogdetail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };
  const [overlayWidth, setOverlayWidth] = useState("100%");
  useEffect(() => {
    const handleScroll = () => {
      // Get the h1 element by its ID
      const h1Element = document.getElementById("homeBlogImgOverlAY");

      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top <= windowHeight * 0.7) {
          setOverlayWidth("0%");
        } else {
          setOverlayWidth("100%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [showLoaderAdmin, setShowLoaderAdmin] = useState(false);
  const [blogData, setcancelledData] = useState([]);

  useEffect(() => {
    master_data_get();
  }, []);
  function truncateWords(text, wordLimit) {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + " ..."
      : text;
  }
  const master_data_get = async () => {
    setShowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("flag", 1);
    await server_post_data(blog, fd)
      .then((Response) => {
        let data = Response.split("~@~");
        if (parseInt(data[0]) === 1) {
          handleError(data[1]);
        } else {
          let final_data = JSON.parse(data[1]);
          setcancelledData(final_data.blog);
          setSEOloop(final_data.seo_list);
        }
        setShowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        setShowLoaderAdmin(false);
      });
  };
  return (
    <>
      {showLoaderAdmin && <Loader />}

      <Header />
      <div style={{ overflow: "hidden" }}>
        <Hero />

        <div className="trusteddiglPartnerDiv">
          <div className="container-lg">
            <h1>TRUSTED DIGITAL MARKETING PATNER</h1>
            <div className="row trustdDigiContnt">
              <div className="col-lg-4 col-md-6 col-12">
                <p>
                  The Fox Advertisement is India’s best digital marketing
                  company in Bhopal, Madhya Pradesh. We pride ourselves on being
                  a leading Digital Marketing agency, driven by our expert team.
                  With years of experience in Digital Marketing Strategy,
                  Branding, SEO Services, UI/UX Design, and Social Media
                  Marketing, our tailored services can boost sales, increase
                  engagement.
                </p>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <p>
                  The Fox Advertisement is India’s best digital marketing
                  company in Bhopal, Madhya Pradesh. We pride ourselves on being
                  a leading Digital Marketing agency, driven by our expert team.
                  With years of experience in Digital Marketing Strategy,
                  Branding, SEO Services, UI/UX Design, and Social Media
                  Marketing, our tailored services can boost sales, increase
                  engagement.
                </p>
              </div>
            </div>
          </div>
        </div>

        <RecentWork />
        <BrandTrusted />
        <Testimonial />

        <div className="blogHomePage">
          <div id="blogHome" className="headingBLog">
            <div className="headingBLogunderData">
              <h1
                style={{
                  transition: "width 0.5s ease-out",
                  width: h1Width,
                }}
              >
                FROM THE BLOG{" "}
              </h1>
              <p
                style={{
                  transition: "width 0.5s ease-out",
                  width: h1Width,
                }}
              >
                {" "}
                CHECK OUR LATEST NEWS
              </p>
            </div>
          </div>
          <div id="homeBlogImgOverlAY" className="row mx-3">
            {blogData &&
              blogData.length > 0 &&
              blogData.slice(0, 3).map((blog, index) => (
                <div
                  key={index}
                  className="col-lg-4 col-md-6 col-12 paddingNone"
                >
                  <div
                    className="blogData"
                    onClick={() =>
                      handleNavigationRoute(
                        "/" + match_and_return_seo_link(blog.b_id)
                      )
                    }
                    style={{ display: "block" }}
                  >
                    <div
                      className="HomeBLoimgDiv"
                      style={{ position: "relative" }}
                    >
                      <div
                        className="overlay"
                        style={{
                          width: overlayWidth,
                          transition: "width 0.8s ease-in-out",
                        }}
                      ></div>

                      <LazyLoadImage
                        src={blog.image_name}
                        alt={`Image for blog titled ${blog.title_name}`}
                        title={`Click to read more about ${blog.title_name}`}
                        effect="blur"
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          maxHeight: "100%",
                        }}
                      />
                    </div>
                    <div className="container-lg">
                      <h2
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: 2,
                          lineHeight: "1.5em",
                        }}
                      >
                        {blog.title_name}
                      </h2>

                      <div className="dateAndTxtDiv">
                        <p>{blog.author}</p>
                        <p>{formatDateString(blog.entry_date)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="loadMoreBttn">
            <button
              type="button"
              onClick={() => handleNavigationRoute("/blog")}
            >
              Show More
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
