import { useState } from "react";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import "./Css/contact.css";
import conctimg1 from "../Assets/conctimg1.svg";
import conct2 from "../Assets/conct2.svg";
import conct3 from "../Assets/conct3.svg";
import {
  server_post_data,
  save_contact_us,
} from "../ServiceConnection/serviceconnection";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleError,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleSuccess,
} from "../CommonJquery/CommonJquery.js";
import Loader from "./Reapeting Componets/Loader";
let click_time = 0;
export default function Contact() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [RequirmentData, setRequirmentData] = useState([]);
  const Requirement = [
    { src: conctimg1, alt: "Startup/D2C Brand", title: "Startup/D2C Brand" },
    {
      src: conct2,
      alt: "Exiting/New Bussiness",
      title: "Exiting/New Bussiness",
    },
    { src: conct3, alt: "Product/tech Company", title: "Product/tech Company" },
  ];
  const [activeIndices, setActiveIndices] = useState([]); // Store multiple active indices

  const handleClick = (index, image) => {
    setActiveIndices((prevActiveIndices) => {
      if (prevActiveIndices.includes(index)) {
        return prevActiveIndices.filter((item) => item !== index);
      } else {
        return [...prevActiveIndices, index];
      }
    });

    setRequirmentData((prevData) => {
      if (prevData.includes(image.title)) {
        return prevData.filter((title) => title !== image.title);
      } else {
        return [...prevData, image.title];
      }
    });
  };

  const handleSaveChangesdynamic = async (form_data, save_venueowner) => {
    let vaild_data = check_vaild_save(form_data);
    console.log(vaild_data);
    if (vaild_data && click_time === 0) {
      click_time = 1;
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      console.log(RequirmentData.join("~@~"));
      fd_from.append("Requirement", RequirmentData.join("~@~"));
      await server_post_data(save_venueowner, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          let data1 = Response.split("~@~");
          if (parseInt(data1[0]) === 1) {
            handleError(data1[1]);
          } else {
            click_time = 0;
            handleSuccess(data1[1]);
            empty_form("");
          }
        })
        .catch((error) => {
          click_time = 0;
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  return (
    <div>
      {showLoaderAdmin && <Loader />}
      <Header />

      <div className="contactUsCOntr">
        <div className="container-lg">
          <div className="row letGetThis">
            <div className="col-md-6 col-12 leftData">
              <h1>Let's Get This Rolling!</h1>
              <div className="orengliLine"></div>
            </div>
            <div className="col-md-6 col-12 rightData">
              <p>
                We can neither confirm nor deny that we read minds, so give us a
                bit of background on what you’re looking for.
              </p>
            </div>
          </div>
          <div className="formDataconct">
            <div className="formDataShow formCOntact">
              <form className="row" id="FormDataFinal">
                <div className="col-md-6 col-12 mt-3">
                  <label>
                    Your Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="form-control trio_mandatory"
                    id="name"
                    name="name"
                    maxLength={50}
                    onChange={handleAphabetsChange}
                  />
                  <span className="condition_error"></span>
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label>
                    Your Name Email Address<span>*</span>
                  </label>

                  <input
                    type="test"
                    placeholder="Email"
                    id="email_name"
                    name="email_name"
                    maxLength={50}
                    className="form-control trio_mandatory"
                    onChange={handleEmailChange}
                  />
                  <span className="condition_error"></span>
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label>
                    Company Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Your Company Name"
                    className="form-control trio_mandatory"
                    id="company"
                    name="company"
                    maxLength={50}
                    onChange={handleAphabetsChange}
                  />
                  <span className="condition_error"></span>
                </div>

                <div className="col-md-6 col-12 position-relative mt-3">
                  <label>
                    Contact Number<span>*</span>
                  </label>
                  <input
                    type="tel"
                    placeholder="Enter phone number"
                    className="form-control trio_mandatory"
                    onChange={handleNumbersChange}
                    id="mobile"
                    name="mobile"
                    maxLength={10}
                  />
                  <span className="condition_error"></span>
                </div>

                <div className="theseAReDIv mt-3">
                  <label>
                    Your Requirement<span>*</span>
                  </label>

                  <div className="BOXdIVcONTCT">
                    <div className="row m-0 p-0">
                      {Requirement.map((image, index) => (
                        <div className="col-sm-4 col-6 mb-3 paddnngZero ">
                          <img
                            key={index}
                            src={image.src}
                            alt={image.alt}
                            title={image.title}
                            name={image.title}
                            className={
                              activeIndices.includes(index) ? "active" : "" 
                            }
                            onClick={() => handleClick(index, image)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 mt-3">
                  <label>
                    Project Budget [In Lakhs / In USD]<span>*</span>
                  </label>

                  <input
                    type="text"
                    placeholder="Enter your Project Budget"
                    className="form-control trio_mandatory"
                    id="Budget"
                    name="Budget"
                    maxLength={6}
                    onClick={handleIaphabetnumberChange}
                  />
                  <span className="condition_error"></span>
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label>
                    Project Deadline<span>*</span>
                  </label>
                  <select
                    className="form-control trio_mandatory"
                    id="Deadline"
                    name="Deadline"
                  >
                    <option value="" disabled selected hidden>
                      Select
                    </option>
                    <option value="1~2~Month">1-2 Month</option>
                    <option value="2~4~Month">2-4 Month</option>
                    <option value="4~6~Month">4-6 Month</option>
                  </select>
                  <span className="condition_error"></span>
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label>
                    Your Message<span>*</span>
                  </label>

                  <textarea
                    type="text"
                    className="form-control trio_mandatory"
                    placeholder="Let us know about your project"
                    id="query"
                    name="query"
                    maxLength={550}

                  />
                  <span className="condition_error"></span>
                </div>
                <div className="col-md-6 col-12 mt-3">
                  <label>
                    How did you hear about us?<span>*</span>
                  </label>
                  <select
                    className="form-control trio_mandatory"
                    name="platform"
                    id="platform"
                  >
                    <option value="" disabled selected hidden>
                      Select
                    </option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Recommended by past client">
                      Recommended by past client
                    </option>
                  </select>
                  <span className="condition_error"></span>
                </div>
              </form>
            </div>
          </div>
          <div class="submitBttn">
            <div className="translate"></div>
            <button
              type="button"
              onClick={() =>
                handleSaveChangesdynamic("FormDataFinal", save_contact_us)
              }
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
