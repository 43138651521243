import { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Css/Testimonial.css";
import Testi1 from "../Assets/testi1.jpeg";
import Testi2 from "../Assets/testi2.jpeg";
import Testi3 from "../Assets/testi3.jpeg";
import Testi4 from "../Assets/testi4.jpeg";

// Testimonial data
const testimonials = [
  {
    name: "John Doe",
    text: "Working with The Fox Advertisement has been a game-changer for my business. Their SEO and content marketing strategies have significantly boosted our online visibility, bringing in more organic traffic and increasing our sales by 30% in just six months. The team is professional, responsive, and truly understands our needs. Highly recommended!",
    image: Testi1,
  },
  {
    name: "Jane Smith",
    text: "The Fox Advertisement has been an invaluable partner in our digital marketing efforts. Their expertise in social media marketing and branding has helped us create a cohesive and engaging online presence. Since partnering with them, we've seen a remarkable improvement in brand awareness and customer engagement. Their innovative approach and dedication to our success are unparalleled.",
    image: Testi2,
  },
  {
    name: "Mark Johnson",
    text: "The lead generation services provided by The Fox Advertisement have transformed our customer acquisition process. We now have a steady stream of qualified leads, which has led to a 40% increase in our conversion rate. Their team's knowledge and attention to detail are impressive, and they always go the extra mile to ensure our campaigns are successful. I couldn't be happier with the results.",
    image: Testi3,
  },
  {
    name: "Mark Johnson",
    text: "As a startup, we needed a marketing agency that could help us build our brand from the ground up. The Fox Advertisement exceeded our expectations with its comprehensive branding strategy and content marketing services. They helped us define our brand identity, create compelling content, and reach our target audience effectively. Thanks to their efforts, we've launched our product and gained a strong foothold in the market.",
    image: Testi4,
  },
];

//testimonial carrausal  Design
const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 4000,
  pauseOnHover: false,
  arrows: false,
};

export default function Testimonial() {
  const [h1Width, setH1Width] = useState("0%");
  useEffect(() => {
    const handleScroll = () => {
      // Get the h1 element by its ID
      const h1Element = document.getElementById("ourClientsH1");

      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top >= 0 && h1Top <= windowHeight * 0.6) {
          setH1Width("100%");
        } else {
          setH1Width("0%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const h1Element = document.getElementById("ourClientsH1");
      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top >= 0 && h1Top <= windowHeight * 0.8) {
          setH1Width("100%");
        } else {
          setH1Width("0%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className="testimonialDIv">
      <div id="ourClientsH1" className="ourTestHead ">
        <h1
          className="text-center"
          style={{
            transition: "width 1.5s ease-out",
            width: h1Width,
          }}
        >
          Our Clients Say
        </h1>
      </div>

      <div className="testiMonialContnr">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div className="testiData">
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonialContent">
                      <div className="imgContainer mb-3">
                        <img
                          src={testimonial.image}
                          alt={`Testimonial from ${testimonial.name}`}
                          title={`Testimonial from ${testimonial.name}`}
                          className="testimonialImg"
                        />
                      </div>
                      <div className="textContainerTexti">
                        <div className="tringleClass"></div>
                        <p className="testimonialText">{testimonial.text}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
