import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ScrollToTop from "./CommonJquery/ScrollToTop";

//Routing Pages
import Home from "./Componets/Home";
import Blog from "./Componets/Blog";
import PageNotFound from "./Componets/PageNotFound";
import Service from "./Componets/Service";
import ServiceDetails from "./Componets/ServiceDetails";
import Portfolio from "./Componets/Portfolio";
import Career from "./Componets/Career";
import Contact from "./Componets/Contact";
import About from "./Componets/About";
import BlogDetail from "./Componets/BlogDetail";
import Industry from "./Componets/Industry";
import EducationIndustry from "./Componets/EducationIndustry";
import HealthCareIndustry from "./Componets/HealthCareIndustry";
import RealStateIndustry from "./Componets/RealStateIndustry";
import FoodIndustry from "./Componets/FoodIndustry";
import FoxAcedamy from "./Componets/FoxAcedamy";
import {
  get_seo_data_website,
  server_post_data,
} from "./ServiceConnection/serviceconnection.js";
function App() {
  const isVisible = useSelector((state) => state.div.isVisible);

  const [SEOloop, setSEOloop] = useState([]);
  const match_and_return_seo_link_and_page = (
    call_function_name,
    pretty_function_name,
    dynamic_title_ss,
    favicon_sss
  ) => {
    let data_seo_link_final = "";

    if (call_function_name === "/") {
      data_seo_link_final = pretty_function_name + "~@~1";
    } else if (call_function_name === "/blog") {
      data_seo_link_final = pretty_function_name + "~@~2";
    } else if (call_function_name.includes("blog/blogdetail/")) {
      data_seo_link_final = pretty_function_name + "~@~3";
    } else if (call_function_name === "/services") {
      data_seo_link_final = pretty_function_name + "~@~4";
    } else if (call_function_name.includes("services/serviceDetails/")) {
      data_seo_link_final = pretty_function_name + "~@~5";
    } else if (call_function_name === "/portfolio") {
      data_seo_link_final = pretty_function_name + "~@~6";
    } else if (call_function_name === "/career") {
      data_seo_link_final = pretty_function_name + "~@~7";
    } else if (call_function_name === "/contact") {
      data_seo_link_final = pretty_function_name + "~@~8";
    } else if (call_function_name === "/about") {
      data_seo_link_final = pretty_function_name + "~@~9";
    } else if (call_function_name === "/industry") {
      data_seo_link_final = pretty_function_name + "~@~10";
    } else if (call_function_name.includes("industry/industry_detail/")) {
      data_seo_link_final = pretty_function_name + "~@~11";
    } else if (call_function_name === "/fox_academy") {
      data_seo_link_final = pretty_function_name + "~@~12";
    }
    return data_seo_link_final;
  };

  const get_page_name = (page_number) => {
    let data_seo_link_final = "";

    if (page_number === "1") {
      data_seo_link_final = <Home />;
    } else if (page_number === "2") {
      data_seo_link_final = <Blog />;
    } else if (page_number === "3") {
      data_seo_link_final = <BlogDetail />;
    } else if (page_number === "4") {
      data_seo_link_final = <Service />;
    } else if (page_number === "5") {
      data_seo_link_final = <ServiceDetails />;
    } else if (page_number === "6") {
      data_seo_link_final = <Portfolio />;
    } else if (page_number === "7") {
      data_seo_link_final = <Career />;
    } else if (page_number === "8") {
      data_seo_link_final = <Contact />;
    } else if (page_number === "9") {
      data_seo_link_final = <About />;
    } else if (page_number === "10") {
      data_seo_link_final = <Industry />;
    } else if (page_number === "11") {
      data_seo_link_final = <EducationIndustry />;
    } else if (page_number === "12") {
      data_seo_link_final = <FoxAcedamy />;
    }

    return data_seo_link_final;
  };

  const handleFetchData = async () => {
    await server_post_data(get_seo_data_website, null)
      .then((Response) => {
        setSEOloop(Response);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  const dynamicRoutes = useMemo(() => {
    try {
      // Defensive programming: Add error handling
      if (!SEOloop || SEOloop.length === 0) {
        console.warn("SEOloop is empty or undefined");
        return [];
      }

      return SEOloop.map((data, index) => {
        try {
          // Safe route generation with extensive error checking
          const for_loop_come = match_and_return_seo_link_and_page(
            data.call_function_name,
            data.pretty_function_name,
            data.title,
            data.favicon
          );

          if (!for_loop_come) {
            return null;
          }

          const stringArray = for_loop_come.split("~@~");

          // Validate route parts
          if (stringArray.length < 2) {
            return null;
          }

          return (
            <Route
              key={index}
              path={stringArray[0]}
              element={get_page_name(stringArray[1])}
            />
          );
        } catch (routeError) {
          return null;
        }
      }).filter((route) => route !== null);
    } catch (error) {
      return [];
    }
  }, [SEOloop]); // Dependency on SEOloop

  return (
    <div className="App">
      <div className={`slide-in-div ${isVisible ? "show" : ""}`}></div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/blogdetail/:id" element={<BlogDetail />} />
          <Route path="services" element={<Service />} />
          <Route
            path="services/serviceDetails/:id"
            element={<ServiceDetails />}
          />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="career" element={<Career />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="industry" element={<Industry />} />
          <Route path="health_care_industry" element={<HealthCareIndustry />} />
          <Route path="real_state_industry" element={<RealStateIndustry />} />
          <Route path="food_industry" element={<FoodIndustry />} />
          <Route path="education_industry" element={<EducationIndustry />} />

          <Route path="fox_academy" element={<FoxAcedamy />} />
          {dynamicRoutes}
          {SEOloop && SEOloop.length > 0 && (
            <Route path="/*" element={<PageNotFound />} />
          )}
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
