import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isVisible: false,
};

const divSlice = createSlice({
  name: "div",
  initialState,
  reducers: {
    showDiv: (state) => {
      state.isVisible = true;
    },
    hideDiv: (state) => {
      state.isVisible = false;
    },
  },
});

export const { showDiv, hideDiv } = divSlice.actions;
export default divSlice.reducer;
