import React, { useState } from "react";
import "./Css/Faq.css";

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is a Digital Marketing Course?",
      answer: "A digital marketing course is skill-based to gain insights and experience from real industry using AI-powered tools. This course helps you to learn in-demand skills like SEO, SMM, Google Ads, PPC, Email and Content Marketing etc.",
    },
    {
      question: "Are the classes live or recorded?",
      answer: "All the classes are live and conducted over Zoom.",
    },
    {
      question: "Is this course suitable for beginners?",
      answer: "Absolutely! The course is designed to make it suitable for beginners as well as advanced professionals to enhance their skills.",
    },
    {
      question: "Do you provide placement support?",
      answer: "Yes, we do. We have a dedicated placement support team to help individuals get their dream jobs after completing the course.",
    },
    {
      question: "Will I get an internship opportunity?",
      answer: "Yes, we offer candidates internships to gather hands-on experience from live projects.",
    },
    {
      question: "Do you offer a demo class before enrolling?",
      answer: "Answer 6",
    },
    {
      question: "How will industry experts provide practical insights and real-life examples during the training sessions?",
      answer: "Yes, we offer demo classes so that you can check the quality of the digital marketing online course. When you are satisfied you can enrol for the course and start learning.",
    },
    {
      question: "How can I enrol for the course?",
      answer: "You can enrol in the course by visiting our website or reaching out to our support team for assistance in the registration process.",
    },
  ];

  return (
    <div className="faq">
      <div className="faq__title">
        <div className="d-flex flex-column align-items-center">
          {" "}
          <h1>Frequently Asked Questions</h1>
          <p>We have answers (Well, most of the times!)</p>
        </div>
      </div>

      <div className="faq__content">
        {faqData.map((item, index) => (
          <div key={index} className="faq__item">
            <div
              className={`faq__question ${
                activeIndex === index ? "faq__question--active" : ""
              }`}
              onClick={() => toggleAnswer(index)}
            >
              <span>{item.question}</span>
              <span
                className={`faq__icon ${
                  activeIndex === index ? "faq__icon--rotated" : ""
                }`}
              >
                ▼
              </span>
            </div>
            <div
              className={`faq__answer ${
                activeIndex === index ? "faq__answer--visible" : ""
              }`}
            >
              <div className="answerDIv">{item.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
