import React, { useState } from "react";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import tickmarkImg from "../Assets/tickmarkImg.png";
import {
  server_post_data,
  save_contact_us,
} from "../ServiceConnection/serviceconnection";
import add1 from "../Assets/add1.png";
import add2 from "../Assets/add2.png";
import add3 from "../Assets/add3.png";
import add4 from "../Assets/add4.png";
import add5 from "../Assets/add5.png";
import add6 from "../Assets/add6.png";
import add7 from "../Assets/add7.png";
import add8 from "../Assets/add8.png";
import opp1 from "../Assets/opp1.png";
import opp2 from "../Assets/opp2.png";
import opp3 from "../Assets/opp3.png";
import opp4 from "../Assets/opp4.png";
import opp5 from "../Assets/opp5.png";
import opp6 from "../Assets/opp6.png";
import opp7 from "../Assets/opp7.png";
import opp8 from "../Assets/opp8.png";
import opp9 from "../Assets/opp9.png";
import opp10 from "../Assets/opp10.png";
import googleIcon from "../Assets/googleIcon.png";
import seoImg from "../Assets/seoImg.png";
import b2cImg from "../Assets/b2cImg.png";
import optimizeImg from "../Assets/optimizeImg.png";
import wordPressIcpm from "../Assets/wordPressIcpm.png";
import socialMediaIcon from "../Assets/socialMediaIcon.png";
import growthImg from "../Assets/growthImg.png";
import aiImg from "../Assets/aiImg.png";
import downArrow from "../Assets/downArrow.svg";
import youtubeIcon from "../Assets/youtubeIcon.png";
import imgCourse1 from "../Assets/imgCourse1.png";
import imgCourse2 from "../Assets/imgCourse2.png";
import imgCourse3 from "../Assets/imgCourse3.png";
import imgCourse4 from "../Assets/imgCourse4.png";
import certictaLetter from "../Assets/certictaLetter.jpeg";
import Faq from "./Faq";
import "./Css/FoxAcedamy.css";
import { handleError, handleSuccess } from "../CommonJquery/CommonJquery";
import Loader from "./Reapeting Componets/Loader";
export default function FoxAcedamy() {
  const [showEmploy, setshowEmploy] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("Select Working Status");

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [termsAgreed, setTermsAgreed] = useState(false);

  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
    email: "",
    city: "",
    state: "",
  });
  const HandleshowEmployStatus = () => {
    setshowEmploy(!showEmploy);
  };

  const options = ["Fresher", "Working Professional"];
  const handleSelectStatus = (status) => {
    setSelectedStatus(status);
    setshowEmploy(false);
  };

  const adsData = [
    { src: add1, alt: "add1", title: "add1", label: "Google Ads Logo" },
    { src: add2, alt: "add1", title: "add1", label: "Semrush" },
    { src: add3, alt: "add1", title: "add1", label: "GTM" },
    { src: add4, alt: "add1", title: "add1", label: "Google Analytics" },
    { src: add5, alt: "add1", title: "add1", label: "FB Ads" },
    { src: add6, alt: "add1", title: "add1", label: "Zapier" },
    { src: add7, alt: "add1", title: "add1", label: "Ahref" },
    { src: add8, alt: "add1", title: "add1", label: "Spyfu" },
  ];

  const jobOpportunities = [
    { title: "Digital Marketing Manager", image: opp1 },
    { title: "Content Marketers", image: opp2 },
    { title: "SEO Specialist", image: opp3 },
    { title: "SEM/PPC Specialist", image: opp4 },
    { title: "Social Media Manager", image: opp5 },
    { title: "Email Marketing Specialist", image: opp6 },
    { title: "Analytics Manager", image: opp7 },
    { title: "Conversion Rate Optimizer", image: opp8 },
    { title: "Influencer Marketer", image: opp9 },
    { title: "Digital Marketing Strategist", image: opp10 },
  ];
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const sections = [
    { imgSrc: googleIcon, title: "What is Google? How Google Works?" },
    { imgSrc: seoImg, title: "Search Engine Optimisation (SEO)" },
    { imgSrc: optimizeImg, title: "Customer Engagement" },
    { imgSrc: b2cImg, title: "B2C v/s B2B Marketing" },
    { imgSrc: wordPressIcpm, title: "Build Your own Website!" },
    { imgSrc: socialMediaIcon, title: "Social Media Marketing" },
    { imgSrc: growthImg, title: "Growth Marketing Affiliate Marketing" },
    { imgSrc: aiImg, title: "AI in Digital Marketing Grow your business" },
  ];
  const courseDetails = [
    {
      imgSrc: imgCourse1,
      altText: "imgCourse1",
      title: "Course1",
      label: "Placement Assistance",
    },
    {
      imgSrc: imgCourse2,
      altText: "imgCourse2",
      title: "Course2",
      label: "Certification",
    },
    {
      imgSrc: imgCourse3,
      altText: "imgCourse3",
      title: "Course3",
      label: "Learn from Industry Leaders",
    },
    {
      imgSrc: imgCourse4,
      altText: "imgCourse4",
      title: "Course4",
      label: "Doubt Support & Community Access",
    },
  ];
  const handleActive = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const topPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offset = window.innerHeight * 0.2;
      console.log("Scrolling to position:", topPosition - offset);
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9@.]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!termsAgreed) {
      alert("Please agree to the terms and conditions before submitting.");
      return;
    }

    let isValid = true;
    let newErrors = { ...errors };

    // Validate name (min 3, max 50 characters)
    if (name.length < 3 || name.length > 50) {
      newErrors.name = "Name must be between 3 and 50 characters";
      isValid = false;
    } else {
      newErrors.name = "";
    }

    // Validate mobile number (must be exactly 10 digits)
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(mobile)) {
      newErrors.mobile = "Mobile number must be 10 digits";
      isValid = false;
    } else {
      newErrors.mobile = "";
    }

    // Validate email
    if (!validateEmail(email)) {
      newErrors.email =
        "Email must only contain alphanumeric characters, @, and .";
      isValid = false;
    } else {
      newErrors.email = "";
    }

    // Validate city and state (min length 3 characters)
    if (city.length < 3) {
      newErrors.city = "City must be at least 3 characters long";
      isValid = false;
    } else {
      newErrors.city = "";
    }

    if (state.length < 3) {
      newErrors.state = "State must be at least 3 characters long";
      isValid = false;
    } else {
      newErrors.state = "";
    }

    setErrors(newErrors);

    if (isValid) {
      try {
        console.log({
          name,
          mobile,
          email,
          state,
          city,
          status: selectedStatus,
          termsAgreed,
        });

        let fd_from = new FormData();
        fd_from.append("name", name);
        fd_from.append("mobile", mobile);
        fd_from.append("email_name", email);
        fd_from.append("state", state);
        fd_from.append("city", city);
        fd_from.append("emp_type", selectedStatus);

        setshowLoaderAdmin(true);

        const response = await server_post_data(save_contact_us, fd_from);
        let data1 = response.split("~@~");

        setshowLoaderAdmin(false);

        if (parseInt(data1[0]) === 1) {
          handleError(data1[1]);
        } else {
          handleSuccess(data1[1]);
          setName("");
          setEmail("");
          setMobile("");
          setState("");
          setCity("");
          setSelectedStatus("Select Working Status");
        }
      } catch (error) {
        console.error(error);
        setshowLoaderAdmin(false);
      }
    } else {
      document.querySelectorAll(".form-control").forEach((input) => {
        if (input.value === "" || input.classList.contains("invalid")) {
          input.classList.add("shake");
          setTimeout(() => {
            input.classList.remove("shake");
          }, 500);
        }
      });
    }
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;

    if (field === "name") {
      setName(value);
      if (value) {
        setErrors((prevErrors) => ({ ...prevErrors, name: "" }));
      }
    } else if (field === "mobile") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setMobile(numericValue);

      if (numericValue) {
        setErrors((prevErrors) => ({ ...prevErrors, mobile: "" }));
      }
    } else if (field === "email") {
      // Allow only alphanumeric characters, @, and .
      const validEmailValue = value.replace(/[^a-zA-Z0-9@.]/g, "");
      setEmail(validEmailValue);

      if (validEmailValue) {
        setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
      }
    } else if (field === "state") {
      setState(value);
      if (value) {
        setErrors((prevErrors) => ({ ...prevErrors, state: "" }));
      }
    } else if (field === "city") {
      setCity(value);
      if (value) {
        setErrors((prevErrors) => ({ ...prevErrors, city: "" }));
      }
    }
  };

  return (
    <div>
      {showLoaderAdmin && <Loader />}
      <Header />

      <div className="heroSectionFoxAc">
        <div className="container-lg">
          {" "}
          <div className="row w-100  align-items-center">
            <div className="col-md-6 col-12 mt-3 paddingNoneClass">
              <div className="leftBoxFoxacd">
                <h1> Learn Digital Marketing with Ai</h1>
                <h2>
                  Best Digital Marketing Institute in Bhopal with 100% Placement
                  Support{" "}
                </h2>
                <h3>
                  Master Digital Marketing in Just 3 Months from The Industry
                  Experts and Build Your Professional Career in Digital
                  Marketing
                </h3>

                <div className="listBoxTypes">
                  <div className="row">
                    <div className="col-md-6 ">
                      {" "}
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={tickmarkImg}
                          alt="tickmarkImg"
                          title="tickmarkImg"
                        ></img>

                        <p>Learn from Industry Experts</p>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={tickmarkImg}
                          alt="tickmarkImg"
                          title="tickmarkImg"
                        ></img>

                        <p>100% Practical Class</p>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={tickmarkImg}
                          alt="tickmarkImg"
                          title="tickmarkImg"
                        ></img>

                        <p>Work on Live Project</p>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={tickmarkImg}
                          alt="tickmarkImg"
                          title="tickmarkImg"
                        ></img>

                        <p>Certification</p>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      {" "}
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={tickmarkImg}
                          alt="tickmarkImg"
                          title="tickmarkImg"
                        ></img>

                        <p>Learn from Industry Experts</p>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={tickmarkImg}
                          alt="tickmarkImg"
                          title="tickmarkImg"
                        ></img>

                        <p>50+ Modules</p>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={tickmarkImg}
                          alt="tickmarkImg"
                          title="tickmarkImg"
                        ></img>

                        <p>Placement Facility</p>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={tickmarkImg}
                          alt="tickmarkImg"
                          title="tickmarkImg"
                        ></img>

                        <p>Easy Installments</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 mt-3 paddingNoneClass">
              <div id="ResgistratioForm" className="RightBoxFoxacd">
                <h1>Register Now</h1>
                <div className="formDataFocAcedmy">
                  <div>
                    <input
                      type="text"
                      className={`form-control ${errors.name ? "invalid" : ""}`}
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => handleInputChange(e, "name")}
                      minLength={3}
                      maxLength={50}
                    />
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.mobile ? "invalid" : ""
                      }`}
                      placeholder="Mobile Number"
                      value={mobile}
                      onChange={(e) => handleInputChange(e, "mobile")}
                      maxLength={10}
                    />
                    {errors.mobile && (
                      <span className="error">{errors.mobile}</span>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.email ? "invalid" : ""
                      }`}
                      placeholder="Email"
                      value={email}
                      onChange={(e) => handleInputChange(e, "email")}
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.state ? "invalid" : ""
                      }`}
                      placeholder="State"
                      value={state}
                      onChange={(e) => handleInputChange(e, "state")}
                      minLength={3}
                    />
                    {errors.state && (
                      <span className="error">{errors.state}</span>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      className={`form-control ${errors.city ? "invalid" : ""}`}
                      placeholder="City"
                      value={city}
                      onChange={(e) => handleInputChange(e, "city")}
                      minLength={3}
                    />
                    {errors.city && (
                      <span className="error">{errors.city}</span>
                    )}
                  </div>

                  <div
                    className="selectWorkingSta"
                    onClick={HandleshowEmployStatus}
                  >
                    <h5>{selectedStatus}</h5>
                    <img src={downArrow} alt="open" title="clickMe" />
                    <div
                      className={`SelectWorkingDropdwn ${
                        showEmploy ? "open" : ""
                      }`}
                    >
                      {options.map((status) => (
                        <button
                          key={status}
                          onClick={() => handleSelectStatus(status)}
                        >
                          {status}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="termAndCOnditionDIv">
                    <input
                      type="checkbox"
                      checked={termsAgreed}
                      onChange={() => setTermsAgreed(!termsAgreed)}
                    />
                    <p>
                      I agree to receive information regarding my submitted
                      application by signing up on The Fox Advertisement*
                    </p>
                  </div>

                  <div className="submitFormFoxAcd">
                    <button type="button" onClick={handleSubmit}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="w-100 h-100 bgCOlor">
        {" "}
        <div className="container-lg ">
          <div className="bestDigitlMarkingDiv">
            <div className="bestDigiHEad">
              <h1>Best Digital Marketing Institute in Bhopal</h1>
            </div>

            <div className="bestDigiData">
              <p>
                In today’s digital landscape, Digital Marketing is crucial. By
                leveraging Digital Marketing, businesses can reach to target
                audiences, enhance customer engagement and increase conversion
                rates. We, at The Fox Academy offer professional Digital
                Marketing Course to learn and gain insights from industry
                experts. Our Digital Marketing Course is tailored to experience
                real industry and use digital tools and platforms to empower the
                candidates to secure 100% placement in giant companies.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w-100 h-100 bgCOlor">
        {" "}
        <div className="container-lg ">
          <div className="bestDigitlMarkingDiv">
            <div className="bestDigiHEad">
              <h1>Introduction to the Course</h1>
            </div>
            <a
              href="https://www.youtube.com/watch?v=rXtaM3x7kHA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="thumbnailcontainer">
                <div className="overLayclass"></div>

                <img
                  src={youtubeIcon}
                  alt="YouTube Icon for Fox Academy"
                  title="Watch Fox Academy's Introduction Video on YouTube"
                ></img>
              </div>
            </a>
          </div>
          <div className="BatchDivShow">
            <div className="row widthClass">
              {" "}
              <div className=" col-lg-3 col-md-4 col-sm-6 col-6">
                <div className="boxDiv">
                  <h2>Batch Stars on</h2>
                  <h1>15th Sep</h1>
                </div>
              </div>
              <div className=" col-lg-3 col-md-4 col-sm-6 col-6 bordeLft">
                {" "}
                <div className="boxDiv ">
                  <h2>60 Days</h2>
                  <h1>Practical Training</h1>
                </div>
              </div>
              <div className=" col-lg-3 col-md-4 col-sm-6 col-6 bordeRight">
                {" "}
                <div className="boxDiv3">
                  <h2>30 Days</h2>
                  <h1>Internship</h1>
                </div>
              </div>
              <div className=" col-lg-3 col-md-4 col-sm-6 col-6">
                {" "}
                <div className="boxDivlast">
                  <h2>150+</h2>
                  <h1>Hours of Learning</h1>
                </div>
              </div>
            </div>
          </div>
          {/**----------Master Digital--------- */}
          <div className="bestDigitlMarkingDiv">
            <div className="bestDigiHEad">
              <h1>
                Master Digital Marketing Tools like...
                <p className="harnessDIv">
                  Harness the power of Digital Marketing Tools and simplify
                  complex works
                </p>
              </h1>
            </div>

            <div className="adsDIv">
              {adsData.map((ad, index) => (
                <div key={index} className="contentAdts">
                  <img src={ad.src} alt={ad.alt} title={ad.title} />
                  <label>{ad.label}</label>
                </div>
              ))}
            </div>
          </div>
          {/**-------Job Opportunities Post Completing This Course------- */}
          <div className="bestDigitlMarkingDiv">
            <div className="bestDigiHEad">
              <h1>Job Opportunities Post Completing This Course</h1>
            </div>

            <div className="JobOpportunitidiv">
              <div className="row">
                {jobOpportunities.map((job, index) => (
                  <div
                    key={index}
                    className="col-lg-3 col-md-4 col-sm-6 col-6 mt-3 "
                  >
                    <div className="contentAdts jobOppCOnt">
                      <img src={job.image} alt={job.title} title={job.title} />
                      <label>{job.title}</label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="letswtyDIv">
              <button onClick={() => handleActive("ResgistratioForm")}>
                Lets Get Started!
              </button>
            </div>

            <div class="whatGoggleCont">
              <div className="row">
                <div className="col-md-6 paddingCls">
                  {sections.slice(0, 4).map((section, index) => (
                    <div key={index} className="WHatISgoogle">
                      <img src={section.imgSrc} alt={`icon-${index}`} />
                      <h2>{section.title}</h2>
                    </div>
                  ))}
                </div>
                <div className="col-md-6 paddingCls">
                  {sections.slice(4).map((section, index) => (
                    <div key={index} className="WHatISgoogle">
                      <img src={section.imgSrc} alt={`icon-${index + 4}`} />
                      <h2>{section.title}</h2>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-lg">
        <div className="topcourse">
          <div className="topCopurseHead">
            <h1>Digital Marketing Certification Course</h1>
          </div>

          <div class="topCourseData">
            <div className="row">
              {courseDetails.map((course, index) => (
                <div
                  key={index}
                  className="col-lg-3 col-md-4  col-6  mt-3 position-relative"
                  style={{ overflow: "hidden" }}
                >
                  <img
                    src={course.imgSrc}
                    alt={course.altText}
                    title={course.title}
                  />
                  <div className="placeLabl">
                    <p>{course.label}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="certificationContainrr">
          {" "}
          <div className="certificationDiv">
            <div className="row reverseShow">
              {" "}
              <div className="col-md-7 p-0">
                <div className="lefSideD">
                  <h2>Course Fees</h2>
                  <h1>
                    {" "}
                    <span>₹20000 </span>₹17500
                  </h1>
                  <h3>Easy EMI Available</h3>
                  <p>Seats Are Limited</p>
                  <h4>Live Demo Class Available Enroll Now</h4>
                  <button
                    type="button"
                    onClick={() => handleActive("ResgistratioForm")}
                  >
                    Enroll Now
                  </button>
                </div>
              </div>
              <div className="col-md-5 p-0">
                <div className="righSideDiv">
                  <img
                    src={certictaLetter}
                    alt="Certification Letter"
                    title="Certification Letter"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Faq />
        <div className="bookNowButtn">
          <button
            type="button"
            onClick={() => handleActive("ResgistratioForm")}
          >
            {" "}
            Book A Demo
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}
