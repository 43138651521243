import React, { useState, useRef, useEffect } from "react";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import Testimonial from "./Testimonial";
import "./Css/ServiceDetails.css";
import ourService1 from "../Assets/ourService1";
import "./Css/About.css";

export default function About() {
  const [inView, setInView] = useState([]);
  const headingsRef = useRef([]);

  const services = [
    {
      title: "We are creative",
      subtitile: "Your Go-to Digital Marketing Agency!",
      description: `Our Social Media Marketing Services are designed to help your brand stand out and connect with your target audience. 
                    The Fox Advertisement, a leading social media marketing company in Bhopal, understands the local market and industry trends, 
                    ensuring that businesses capture the attention of the right audiences. From creating impactful content to running successful ad campaigns, 
                    our expert team delivers comprehensive Social Media Marketing solutions that boost your online performance.`,
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = Number(entry.target.dataset.index);
          if (entry.isIntersecting) {
            setInView((prev) => [...prev, index]);
          } else {
            setInView((prev) => prev.filter((i) => i !== index));
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    headingsRef.current.forEach((heading) => {
      observer.observe(heading);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  const [overlayWidth, setOverlayWidth] = useState("100%");
  const [textWidth, setTextwidth] = useState("0");
  const [textWidth2, setTextwidth2] = useState("100");

  useEffect(() => {
    const timer = setTimeout(() => {
      setOverlayWidth("0");
      setTextwidth2("100%");
      setTextwidth("90%");
      setTextwidth2("0");
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Header />

      <div className="searvicDEtailDiv" style={{ overflow: "hidden" }}>
        <div className="heroServive">
          <div className="row  align-items-center">
            <div className="col-lg-6 col-12">
              <div className="leftService">
                <div
                  className="overlay"
                  style={{
                    width: overlayWidth,
                    transition: "width 1.3s ease-in-out",
                  }}
                ></div>
                <img
                  src={ourService1}
                  alt="The Fox Advertisement Service Hero Image"
                  title="The Fox Advertisement Service Hero Image"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="rightService">
                <h1
                  style={{
                    width: textWidth,
                    transition: "width 1.8s ease-in-out",
                  }}
                >
                  unlock your full potential
                </h1>
                <div className="detailServHeroSub">
                  <div
                    className="overlaytEXT"
                    style={{
                      width: textWidth2,
                      transition: "width 1s ease-in-out",
                    }}
                  ></div>
                  <h2>
                    Tailored Digital Marketing Strategy to Boost Your Sales
                  </h2>
                </div>
                <div className="d-flex gap-2 flex-wrap position-relative">
                  <div
                    className="overlaytEXT"
                    style={{
                      fontSize: "28px",
                      width: textWidth2,
                      transition: "width 1s ease-in-out",
                    }}
                  ></div>
                  <h2
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    Plan team
                  </h2>
                  <h2
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    Ideate ideas
                  </h2>
                  <h2
                    style={{
                      fontSize: "28px",
                    }}
                  >
                    Execute services
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whoiWeAreDiv">
        <div className="container-lg">
          <div className="col-lg-11 col-12 mx-auto">
            {" "}
            <h1>Who we are</h1>
            <p>
              The Fox Advertisement, the best digital marketing company in India
              was started in 2020 by{" "}
              <span>
                <a
                  href="https://www.linkedin.com/in/globalbrandconsultant/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "var(--primary-color)" }}
                >
                  Ashwini Roy
                </a>
              </span>{" "}
              and her team. We are a dynamic and innovative digital marketing
              agency dedicated to helping businesses thrive in the evolving
              digital landscape. Our experienced professionals offer a wide
              range of services, including SEO, social media marketing, content
              marketing, lead generation, and branding strategy, combining their
              creativity and expertise.
            </p>
          </div>
        </div>
      </div>
      {services.map((service, index) => (
        <div className="serviceDataDet aboutContnt" key={index}>
          <div className="disSerData">
            <h1
              ref={(el) => (headingsRef.current[index] = el)}
              data-index={index}
              style={{
                width: inView.includes(index) ? "100%" : "0%",
                transition: "width 1s ease-in-out",
              }}
            >
              {service.title}
            </h1>
          </div>
          <div className="container-lg">
            <div className="col-lg-11 col-12 mx-auto">
              <div className="d-flex  marginTopcLASS">
                <h2 style={{ fontSize: "23px", textTransform: "uppercase" }}>
                  {" "}
                  {service.subtitile}
                </h2>
              </div>
            </div>
            <div className="col-lg-11 col-12 mx-auto">
              <p className="pdding1rem"> {service.description}</p>
            </div>
          </div>
        </div>
      ))}

      <Testimonial />

      <Footer />
    </>
  );
}
