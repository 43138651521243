import React from "react";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import "./Css/Industry.css";

import NavigationWithAnimation from "../CommonJquery/NavigationWithAnimation";
export default function Industry() {
  const { handleNavigationRoute } = NavigationWithAnimation();
  const industries = [
    {
      icon: "fa-graduation-cap",
      title: "Education Industry",
      description:
        "Digital Marketing for the Education Industry includes SEO Optimization, social media engagement, and targeted paid campaigns to boost enrollment.",
      route: "/education_industry",
    },
    {
      icon: "fa-stethoscope",
      title: "Healthcare Industry",
      description:
        "Healthcare organizations connect with patients by providing reliable information and driving them to the facility through digital channels.",
      route: "/health_care_industry",
    },
    {
      icon: "fa-building",
      title: "Real Estate Industry",
      description:
        "Digital Marketing Services that help real estate agents, brokers, and property developers generate high-quality leads and grow their online presence.",
      route: "/real_state_industry",
    },
    {
      icon: "fa-utensils",
      title: "Food Industry",
      description:
        "The food industry is required to constantly evolve to meet consumers' demands and attract new customers.",
      route: "/food_industry",
    },
  ];

  return (
    <>
      <Header />

      <div style={{ overflow: "hidden" }}>
        <div className="container-lg mt-4">
          <div className="industryHeadline">
            <div className="d-flex flex-column align-items-center">
              <h1>Industries We Serve</h1>
              <p>Education, Healthcare, Real Estate, Food</p>
            </div>
          </div>
          <div className="row">
            {industries.map((industry, index) => (
              <div
                className="col-lg-4 col-md-6 col-12 mt-3 marginReducer"
                key={index}
              >
                <div className="readDataDiv">
                  <div className="indutryData">
                    <i className={`fa ${industry.icon} fa-4x text-color-1`}></i>
                    <h1>{industry.title}</h1>
                    <p>{industry.description}</p>
                  </div>
                </div>
                <div className="ReadMoreDivIndust">
                  <div
                    className="explorBttn explorBttnDiv"
                    onClick={() => handleNavigationRoute(industry.route)}
                  >
                    <button
                      className="explorBttn btn-push-left"
                      data-hover="CLICK ME!"
                      href="#"
                    >
                      <span>Read More</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
