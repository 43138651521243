import axios from "axios";

const appauth_key = "www.swagstay.com";
let APL_LINK = "http://192.168.1.14/thefox_website/";
APL_LINK = "https://adminpanel.thefoxadvertisement.com/";
let Website_URL = "https://www.thefoxadvertisement.com/";

const local_server_link_react = APL_LINK + "ReactMaster/";
const view_testimonial = local_server_link_react + "view_testimonial";
const save_contact_us = local_server_link_react + "save_contact_us";
const blog = local_server_link_react + "blog";
const blog_detail = local_server_link_react + "blog_detail";
const get_jobs = local_server_link_react + "get_jobs";
const job_app_save = local_server_link_react + "job_app_save";
const job = local_server_link_react + "job";
const save_contact_us_ai = local_server_link_react + "save_contact_us_ai";
const get_seo_data_website = local_server_link_react + "get_seo_data_website";

const server_post_data = async (url_for, Data) => {
  try {
    if (Data === null) {
      Data = new FormData();
    }

    const response = await axios.post(url_for, Data, {
      headers: {
        "Content-Type": "multipart/form-data", // If sending FormData
        // Include other headers as needed for your backend
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export {
  APL_LINK,
  server_post_data,
  appauth_key,
  Website_URL,
  view_testimonial,
  job_app_save,
  job,
  save_contact_us_ai,
  save_contact_us,
  blog,
  blog_detail,
  get_jobs,
  get_seo_data_website,
};
