import React, { useState, useEffect } from "react";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import RecentWork from "./RecentWork";
import heroCar2 from "../Assets/heroCar2.jpg";
import "./Css/Portfolio.css";

export default function Portfolio() {
  const [overlayWidth, setOverlayWidth] = useState("100%");
  const [textWidth, setTextwidth] = useState("0");
  const [textWidth2, setTextwidth2] = useState("100");

  useEffect(() => {
    const timer = setTimeout(() => {
      setOverlayWidth("0");
      setTextwidth2("100%");
      setTextwidth("90%");
      setTextwidth2("0");
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {" "}
      <Header />
      <div className="portfolioDiv" style={{ overflow: "hidden" }}>
        <div className="heroServive">
          <div className="row  align-items-center">
            <div className="col-lg-6 col-12">
              <div className="leftService">
                <div
                  className="overlay"
                  style={{
                    width: overlayWidth,
                    transition: "width 1.3s ease-in-out",
                  }}
                ></div>
                <img
                  src={heroCar2}
                  alt="The Fox Advertisement Service Hero Image"
                  title="The Fox Advertisement Service Hero Image"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="rightService">
                <h1
                  style={{
                    width: textWidth,
                    transition: "width 1.8s ease-in-out",
                  }}
                >
                  A brand-NEW
                </h1>
                <h1
                  style={{
                    width: textWidth,
                    transition: "width 1.8s ease-in-out",
                  }}
                >
                  APProach to digital
                </h1>
                <h1
                  style={{
                    width: textWidth,
                    transition: "width 1.8s ease-in-out",
                  }}
                >
                  Marketing strategy
                </h1>
              </div>
            </div>
          </div>
        </div>
        <RecentWork />{" "}
      </div>
      <Footer />
    </>
  );
}
