import React, { useState, useEffect } from "react";
import "./Css/RecentWork.css";
import recnt1 from "../Assets/recnt1.jpg";
import recnt2 from "../Assets/recnt2.jpg";
import recnt3 from "../Assets/recnt3.jpg";
import NavigationWithAnimation from "../CommonJquery/NavigationWithAnimation";
import { useLocation } from "react-router-dom";
const RecentWork = () => {
  const location = useLocation();
  const { handleNavigationRoute } = NavigationWithAnimation();
  const [overlayWidth1, setOverlayWidth1] = useState("100%");
  const [overlayWidth2, setOverlayWidth2] = useState("100%");
  const [overlayWidth3, setOverlayWidth3] = useState("100%");
  const [overlayWidth4, setOverlayWidth4] = useState("100%");
  const [overlayBgColor, setBgColor] = useState("#d03202");

  const handleScrollAnimation = (entries, threshold, setWidth) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setWidth("0%");
        setBgColor("transparent");
      } else {
        setWidth("100%");
        setBgColor("#d03202");
      }
    });
  };

  const handleNavigation = () => {
    if (location.pathname === "/portfolio") {
      window.scrollTo(0, 0);
    } else {
      handleNavigationRoute("/portfolio");
    }
  };
  const scrollToElement = (id, offsetPercentage) => {
    const element = document.getElementById(id);
    if (element) {
      const topPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offset = (window.innerHeight * offsetPercentage) / 100;
      window.scrollTo({
        top: topPosition - offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const observers = [
      {
        id: "element1",
        threshold: 0.3,
        setWidth: setOverlayWidth1,
        offset: 50,
      },
      {
        id: "element2",
        threshold: 0.57,
        setWidth: setOverlayWidth2,
        offset: 25,
      },
      {
        id: "element3",
        threshold: 0.6,
        setWidth: setOverlayWidth3,
        offset: 20,
      },
      {
        id: "element4",
        threshold: 0.57,
        setWidth: setOverlayWidth4,
        offset: 15,
      },
    ];

    // Create IntersectionObservers dynamically for each element
    const observerInstances = observers.map(
      ({ id, threshold, setWidth, offset }) => {
        const observer = new IntersectionObserver(
          (entries) => handleScrollAnimation(entries, threshold, setWidth),
          { root: null, threshold }
        );
        const element = document.getElementById(id);
        if (element) observer.observe(element);

        return { observer, element, offset };
      }
    );
    observerInstances.forEach(({ id, offset }) => {
      const element = document.getElementById(id);
      if (element) {
        scrollToElement(id, offset);
      }
    });

    // Cleanup observers when the component unmounts
    return () => {
      observerInstances.forEach(({ observer, element }) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);
  ///////////////////Maped data

  const recentWorks = [
    {
      id: "element1",
      imgSrc: recnt1,
      overlayWidth: overlayWidth1,
      heading: "AI DRIVEN",
      subheading:
        "Driven Digital Marketing Solution | Best Digital Marketing Company in Bhopal",
      description:
        "Our targeted campaigns are a hit! Our team of digital experts work tirelessly to ensure that every campaign is optimised for success. With us, you are partnering with the best digital marketing company in India, one that is committed to helping you growing your business.",
    },
    {
      id: "element2",
      imgSrc: recnt2,
      overlayWidth: overlayWidth2,
      heading: "NEUROLOGICAL",
      subheading: "Strategy & Branding",
      description:
        "Leveraging smart technology(AI), we have helped clients reach the targeted audience by understanding their vision and mission. Expert-led strategy and marketing boosted brand recognition and people know and identify our client’s awesome brands!",
    },
    {
      id: "element3",
      imgSrc: recnt3,
      overlayWidth: overlayWidth3,
      heading: "LEAD & DEMAND GENRATION",
      subheading: "3X Leads | 10X traffic | 3X revenue:",
      description:
        "Digital Marketing is all about growth, that is exactly what we have delivered to our clients over the years. Through strategic planning, we have generated 5532 high-quality leads. These potential customers are genuinely interested in what our clients offer, keeping their businesses thriving.",
    },
    {
      id: "element4",
      imgSrc: recnt3,
      overlayWidth: overlayWidth4,
      heading: "Performance Marketing",
      subheading: "Growth Hacking for better results:",
      description:
        "Constant tracking and changing our strategy to get our clients the best outcome is our motto. This approach has led to a mind-blowing improvement in their ROI.",
    },
  ];

  return (
    <section className="recentWorkSection">
      <div className="Recentheading">
        <h1>RECENT WORK</h1>
        <p>WHAT WE HAVE TO OFFER</p>
      </div>

      {recentWorks.map((work, index) => (
        <div className="recnt1 marginBttm" key={work.id}>
          <div
            className={`row justify-content-center ${
              index % 2 === 0 ? "flex-row-reverse" : ""
            } align-items-center`}
          >
            <div className="col-md-6 col-12">
              <div
                className="imgDivRecent position-relative BlackColorClass"
                id={work.id}
              >
                <div
                  className="overlay"
                  style={{ width: work.overlayWidth }}
                ></div>
                <img
                  src={work.imgSrc}
                  alt={`Recent work showcasing ${work.heading} - ${work.subheading}`}
                  title={`Recent work: ${work.heading} - ${work.subheading}`}
                  className="recentImage"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="container-lg">
                <div
                  className={
                    index % 2 === 0 ? "textContainer" : "textContainer2"
                  }
                >
                  <div className="col-md-8 col-12">
                    <h1
                      id={work.id}
                      style={{
                        width: work.overlayWidth,
                        backgroundColor: overlayBgColor,
                      }}
                    >
                      {work.heading}
                    </h1>
                    <h2>{work.subheading}</h2>
                    <p>{work.description}</p>
                    <div className="explorBttn">
                      <button
                        className="explorBttn btn-push-left"
                        data-hover="Explore Portfolio"
                        href="#"
                        onClick={handleNavigation}
                      >
                        <span>EXPLORE PORTFOLIO - {work.heading}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default RecentWork;
