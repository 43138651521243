import React, { useState, useEffect } from "react";
import industryDetailImg from "../Assets/industryDetail.jpg";
import Header from "./Reapeting Componets/Header";
import Footer from "./Reapeting Componets/Footer";
import "./Css/BlogDetail.css";
import "./Css/IndustryDetail.css";
import { useLocation } from "react-router-dom";
export default function HealthCareIndustry() {
  const location = useLocation();
  const [overlayWidth, setOverlayWidth] = useState("100%");
  const [textWidth, setTextwidth] = useState("0");
  const [textWidth2, setTextwidth2] = useState("100%");
  const [h1Width, setH1Width] = useState("0%");

  useEffect(() => {
    const handleScroll = () => {
      // Get the h1 element by its ID
      const h1Element = document.getElementById("whatWedo");

      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top >= 0 && h1Top <= windowHeight * 0.8) {
          setH1Width("100%");
        } else {
          setH1Width("0%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // Blog content stored in an array to map through

  ///health care Data
  const healthcaredata = [
    {
      content:
        "Hospitals and healthcare providers are facing increasing competition as more patients rely on online resources for healthcare decisions. Having a strong digital presence is now essential for healthcare providers. With more patients turning to digital platforms for information about healthcare services, digital marketing in the healthcare industry has become crucial for attracting and retaining patients. At Fox Advertisement, we understand the importance of digital marketing in healthcare and provide specialized digital marketing services in the healthcare industry to help hospitals and clinics grow their reputation, reach a wider audience, and establish themselves as trusted care providers.",
    },
    {},
  ];
  const healthcaredata2 = [
    {
      title: "Why Digital Marketing is Essential for the Healthcare Industry",
      content:
        "The healthcare industry has evolved, with patients becoming more informed and selective in their healthcare choices. People are researching online to find reliable medical care, which means that if your hospital or clinic isn’t visible online, you might miss out on potential patients. Digital marketing in the healthcare industry enables healthcare providers to increase their online visibility, connect with potential patients, and build trust through relevant content and positive reviews.",
      content2:
        "Through digital marketing for healthcare industry providers, you can effectively promote your services, showcase patient success stories, and build a stronger connection with your community. The importance of digital marketing in healthcare is not just about reaching a broader audience but about creating meaningful engagement with patients, helping them make informed decisions about their health.",
    },
  ];

  ///main data
  const data = [
    {
      title: "Proven Results",
      description:
        "We’ve helped numerous schools and universities increase their enrollment and enhance their online presence with Digital Marketing for the Education Industry.",
    },
    {
      title: "Industry Expertise",
      description:
        "Our team has deep experience in the education sector, making us highly knowledgeable about the specific needs of schools and universities. We know what works in Digital Marketing in Educational Institutions.",
    },
    {
      title: "Transparentv Reporting",
      description:
        "We provide regular reports that show the performance of your Digital Marketing for Educational Institutes, ensuring you're always informed about the success of your campaigns.",
    },
    {
      title: "Flexible Solutions",
      description:
        "Whether you’re a small institution or a large university, we offer tailored solutions that fit your unique needs and goals.",
    },
    {
      title: "Cost-Effective Approach",
      description:
        "With The Fox Advertisement, you get high-quality, affordable digital marketing solutions. We handle everything from SEO to social media, making it easy for you to focus on what matters—education.  ",
    },
    {
      title: "Affordable and Flexible Marketing Solutions",
      description:
        "We understand that many educational institutions have tight budgets. That’s why we offer flexible, cost-effective digital marketing services specifically designed for the education sector. Whether you need comprehensive Digital Marketing for the Education Industry or focused services like social media management or PPC, we ensure you get results without overspending.",
    },
    {
      title: "Expertise in the Education Sector",
      description:
        "Our experience with schools, colleges, and training institutes allows us to develop highly effective strategies for Digital Marketing in Educational Institutions. We know how to reach students, parents, and other key audiences, helping you stand out in a competitive market.",
    },
    {
      title: "Transparent, Data-Driven Results",
      description:
        "We don’t just launch campaigns; we closely monitor and optimize them to deliver the best results. Our detailed reports show how our Digital Marketing for the education sector impacts your institution, from increasing website traffic to generating more student inquiries.",
    },
    {
      title: "Reputation Management for Schools",
      description:
        "A solid reputation is vital in the education industry. Our reputation management services help your institution maintain a positive online image. We encourage positive reviews, manage feedback, and address any concerns, so prospective students and parents see your school in the best possible light.",
    },
    {
      title: "Increase Enrollment with Targeted Marketing",
      description:
        "At The Fox Advertisement, we understand the challenges of attracting students in today’s digital world. Our Digital Marketing for Educational Institutions is built around targeted strategies to ensure your school reaches the right audience—students and parents who are actively searching for educational opportunities. From SEO to paid ads, we position your institution in front of people at the right moment.",
    },
    {
      title: "Build a Strong Online Presence",
      description:
        "Your website and social media are often the first interaction students and parents have with your institution. Our Digital Marketing for the education sector focuses on building a professional, engaging, and modern online presence. We design websites that are easy to navigate and social media profiles that highlight the unique qualities of your school.",
    },
    {
      title: "Highlight What Makes Your Institution Unique",
      description:
        "Every educational institution has strengths that set it apart, whether it’s expert faculty, special programs, or vibrant campus life. Our Digital Marketing for the Education Industry emphasizes these qualities, ensuring your institution stands out in a crowded field. We create content that showcases your institution’s unique value, helping you attract more students.",
    },

    {
      title: "Improve Your Search Engine Rankings",
      description:
        "When students or parents search for schools online, you need to be visible. Our SEO strategies for Digital Marketing in Education Sector ensure your website ranks higher in search engine results, driving more traffic and applications. We use relevant keywords, high-quality content, and technical SEO to enhance your school’s visibility.",
    },

    {
      title: "Engage Directly with Students and Parents",
      description:
        "Communication is key to maintaining relationships with students, parents, and alumni. Our Digital Marketing for Educational Institutes includes email marketing, social media engagement, and online events, ensuring you stay connected with your audience. These efforts help foster a sense of community and encourage student enrollment.",
    },

    {
      title: "Search Engine Optimization (SEO)",
      description:
        "Our SEO services are a crucial part of Digital Marketing in Educational Institutions. We optimize your website to ensure your institution ranks high on search engines when prospective students are looking for programs. From keyword research to content optimization, we help you attract the right students and increase inquiries.",
    },

    {
      title: "Social Media Marketing",
      description:
        "Engaging social media profiles are essential for modern educational institutions. Our social media strategies for Digital Marketing for Educational Institutes help you connect with students and parents through meaningful, engaging content. Whether it’s sharing success stories or promoting events, we help you build a strong online presence.",
    },

    {
      title: "Pay-Per-Click (PPC) Advertising",
      description:
        "Our PPC campaigns for Digital Marketing in Education Sector provide instant visibility. We create targeted ads that appear when potential students search for specific programs or schools. With PPC, you only pay when someone clicks on your ad, making it a cost-effective way to generate inquiries.",
    },

    {
      title: "Website Design and Development",
      description:
        "Your website is often the first impression students have of your institution. Our team designs user-friendly, visually appealing websites that reflect your values and strengths. As part of our Digital Marketing for the education sector, we ensure that your website is optimized for both users and search engines.",
    },

    {
      title: "Content Creation",
      description:
        "Engage your audience with compelling content. Our content creation services help you showcase your institution’s achievements, programs, and campus life. Through blog posts, videos, and articles, we position your institution as a leader in education, driving more inquiries and building trust with prospective students.",
    },

    {
      title: "Reputation Management",
      description:
        "Managing your institution’s reputation is vital for attracting new students. Our Digital Marketing for Educational Institutes includes reputation management services to ensure your school is always presented in the best light. From encouraging positive reviews to addressing any concerns, we protect your online image.",
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setOverlayWidth("0");
      setTextwidth2("100%");
      setTextwidth("90%");
      setTextwidth2("0");
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const [visibleItems, setVisibleItems] = useState([]);

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisibleItems((prev) => [...prev, entry.target.id]);
      } else {
        setVisibleItems((prev) => prev.filter((id) => id !== entry.target.id));
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.8, // 80% of the element should be visible
    });

    const elements = document.querySelectorAll(".item");
    elements.forEach((element) => observer.observe(element));

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="blogDetaildiv">
      <Header />
      <div className="searvicDEtailDiv" style={{ overflow: "hidden" }}>
        <div className="heroServive">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="leftService">
                <div
                  className="overlay"
                  style={{
                    width: overlayWidth,
                    transition: "width 1.3s ease-in-out",
                  }}
                ></div>
                <div className="imgOverlAy"></div>
                <img
                  src={industryDetailImg}
                  alt="The Fox Advertisement Service Hero Image"
                  title="The Fox Advertisement Service Hero Image"
                  loading="lazy"
                  className="responsive-img"
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="rightService">
                <div className="detailServHeroSubBlogg">
                  <div
                    className="overlaytEXT"
                    style={{
                      width: textWidth2,
                      transition: "width 1s ease-in-out",
                    }}
                  ></div>

                  {/**------------health Industry Url Heading------------ */}

                  <div>
                    <h2>Why Digital Marketing for the Health Industry?</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <div className="avhivedDiv">
          {/*-------------Health  Care data Top--------------- */}

          {healthcaredata.map((section, index) => (
            <div key={index} className="blogDetaiuldata">
              <p>{section.content}</p>
            </div>
          ))}
          {healthcaredata2.map((section, index) => (
            <div key={index} className="RealIndiData">
              {" "}
              <h5>{section.title}</h5>
              <p>{section.content}</p>
              <p className="mt-2">{section.content2}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="whatWeDo mt-4" id="whatWedo">
        <div className="whatWEdoHead">
          {" "}
          <h1
            style={{
              width: h1Width,
            }}
          >
            Why work with the fox Advertisement
          </h1>
        </div>
        <div className="whatWeDoContent">
          <div className="container-lg">
            {data.map((item, index) => (
              <div
                className="row align-items-center justify-content-center marginClass "
                key={index}
              >
                <div
                  className={`col-lg-3 col-md-4 col-12 item paddingNoneee`}
                  id={`item-${index}`}
                >
                  <div className="underDataWhatwedo industryDetailcont">
                    <span>Proven Results</span>
                    <h1
                      style={{
                        opacity: visibleItems.includes(`item-${index}`) ? 1 : 0,
                        transition: "opacity 0.8s ease-in-out",
                      }}
                    >
                      {item.title}
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8 col-12">
                  <p
                    style={{
                      opacity: visibleItems.includes(`item-${index}`) ? 1 : 0,
                      transition: "opacity 0.8s ease-in-out",
                    }}
                  >
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-lg">
        <div className="Letgrowdata">
          <h1>The Importance of Digital Marketing in Healthcare</h1>
          <p>
            The importance of digital marketing in healthcare cannot be
            overstated. As patients increasingly turn to the internet to find
            medical care, hospitals and clinics need to be where their patients
            are — online. By investing in digital marketing for healthcare
            industry providers, you can
          </p>
          <li>Improve patient acquisition by reaching a larger audience. </li>{" "}
          <li>
            {" "}
            Build trust and credibility by showcasing success stories, positive
            reviews, and informative
          </li>{" "}
          <li>
            Maintain an active and engaging social media presence that keeps
            your hospital connected with the community.
          </li>{" "}
          <li>
            {" "}
            Ensure that your hospital is one of the top results when patients
            search for local medical care.
          </li>{" "}
          <p>
            Healthcare digital marketing helps you not only to attract patients
            but also to retain them by keeping your hospital at the forefront of
            their minds through consistent online engagement.
          </p>
        </div>
        <div className="Letgrowdata marginClassHealth">
          <h1>
            Why Choose Fox Advertisement for Healthcare Digital Marketing?
          </h1>
          <p>
            When it comes to digital marketing in the healthcare industry, Fox
            Advertisement stands out by providing tailored solutions that meet
            the specific needs of healthcare providers. We understand the
            nuances of digital marketing for healthcare industry services and
            how to best market your hospital or clinic. Our team focuses on
            building strategies that not only improve your online presence but
            also create trust and credibility with your patients.
          </p>

          <div className="marginClassHealth">
            <strong>Experienced Team</strong>
            <p style={{ marginTop: "0.3rem" }}>
              Our team consists of experts in healthcare digital marketing who
              understand the industry inside and out. We know how to create
              campaigns that resonate with patients and encourage them to choose
              your hospital.
            </p>
          </div>
          <div className="marginClassHealth">
            <strong>Proven Results</strong>
            <p style={{ marginTop: "0.3rem" }}>
              We have a track record of delivering measurable results for our
              clients through our comprehensive digital marketing services in
              the healthcare industry. From improved patient acquisition to
              higher online visibility, we ensure that our strategies drive
              success.
            </p>
          </div>
          <div className="marginClassHealth">
            <strong>Customized Approach</strong>
            <p style={{ marginTop: "0.3rem" }}>
              No two hospitals are the same, and neither are their marketing
              needs. We take a personalized approach to digital marketing in the
              healthcare industry, ensuring that the strategies we implement
              align with your goals and resonate with your target audience.
            </p>
          </div>
        </div>
        <div className="Letgrowdata marginClassHealth">
          <h1>Success with Healthcare Digital Marketing</h1>
          <p>
            To highlight the importance of digital marketing in healthcare, one
            of our recent clients, a large hospital group, struggled to attract
            new patients in a competitive market. After partnering with Fox
            Advertisement, we implemented a full-scale digital marketing for
            healthcare industry campaign, focusing on SEO, social media
            marketing, and reputation management.
          </p>
          <p>
            Within six months, their online visibility increased significantly,
            with their website ranking on the first page of search results for
            local healthcare services. The hospital saw a 35% increase in
            patient inquiries, with a higher patient retention rate, thanks to
            targeted healthcare digital marketing strategies.
          </p>
        </div>
        <div className="Letgrowdata marginClassHealth">
          <h1>Let’s Grow Your Hospital’s Reach Together</h1>
          <p>
            At Fox Advertisement, we specialize in digital marketing in the
            healthcare industry, offering customized strategies to help your
            hospital or clinic attract more patients. Our proven approach to
            digital marketing in the healthcare industry will strengthen your
            online presence, increase visibility, and build a trusted
            reputation. With our expertise in digital marketing in the
            healthcare industry, you can focus on providing exceptional care
            while we handle the marketing. Ready to elevate your digital
            presence? Contact Fox Advertisement today!
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
