import React from "react";
import "../Css/Loader.css";
import loaderImg from "../../Assets/logo-loader.png";

export default function Loader() {
  return (
    <div className="loaderDiv">
      {" "}
      <div className="loading">
        <img className="logo-loading" src={loaderImg} alt="loaderImg"></img>
      </div>
    </div>
  );
}
