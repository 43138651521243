import React, { useState, useEffect, useRef } from "react";
import "./Css/OurSkill.css";
import ourSkill from "../Assets/ourSkill.jpg";

export default function OurSkill() {
  const [skills, setSkills] = useState([
    { name: "Content Marketing", target: 63, progress: 0 },
    { name: "Market Research Written And Design", target: 80, progress: 0 },
    { name: "User Interface", target: 100, progress: 0 },
    { name: "User Experience", target: 93, progress: 0 },
    { name: "Experts & Analytics", target: 75, progress: 0 },
    {
      name: "AI-Driven Reports And Analytics Positioning",
      target: 75,
      progress: 0,
    },
  ]);

  const [isVisible, setIsVisible] = useState(false);
  const [overlayWidth, setOverlayWidth] = useState("100%");
  const skillSectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        threshold: 0.8,
      }
    );

    if (skillSectionRef.current) {
      observer.observe(skillSectionRef.current);
    }

    return () => {
      if (skillSectionRef.current) {
        observer.unobserve(skillSectionRef.current);
      }
    };
  }, []);
  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setSkills((prevSkills) =>
          prevSkills.map((skill) =>
            skill.progress < skill.target
              ? { ...skill, progress: skill.progress + 1 }
              : skill
          )
        );
      }, 20);

      return () => clearInterval(interval);
    }
  }, [isVisible]);

  ////ovwerflow Img Logic
  useEffect(() => {
    const handleScroll = () => {
      // Get the h1 element by its ID
      const h1Element = document.getElementById("ourSeriveImg");

      if (h1Element) {
        const h1Top = h1Element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (h1Top <= windowHeight * 0.7) {
          setOverlayWidth("0%");
        } else {
          setOverlayWidth("100%");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="ourSkillContr" id="ourSkillSection" ref={skillSectionRef}>
      <div className="row bothDivOur">
        <div
          id="ourSeriveImg"
          className="col-lg-6 col-md-5 col-12 leftSideImg "
        >
          <div
            className="overlay"
            style={{
              width: overlayWidth,
              transition: "width 0.8s ease-in-out",
            }}
          ></div>
          <img src={ourSkill} alt="ourSkill" title="ourSkill" />
        </div>

        <div className="col-lg-6  col-12 rightSideCont">
          <div className="ourSkillHeading">
            {" "}
            <h2>Our Skills</h2>
          </div>

          {skills.map((skill, index) => (
            <div className="skillBar" key={index}>
              <div className="bar">
                <p>{skill.name}</p>
                <div
                  className="progress"
                  style={{ width: `${skill.progress}%` }}
                ></div>
              </div>
              <span>{skill.progress}%</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
